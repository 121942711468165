import React from "react";
import styled from "@emotion/styled";
import mediaqueries from '../styles/media';

import Section from "../components/Section";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Anchor from '../components/Anchor';
import Paginator from "../components/Navigation/Navigation.Paginator";

import ArticlesHero from "../sections/articles/Articles.Hero";
import ArticlesList from "../sections/articles/Articles.List";
import Headings from "../components/Headings";
import Paragraph from "../components/Paragraph";

function IdeasPage({ location, pageContext }) {
  const ideas = pageContext.group;
  const authors = pageContext.additionalContext.authors;

  const { tags } = pageContext;

  return (
    <Layout>
      <SEO
        title="Startup Ideas - Nucks"
        description="An idea's value comes from its execution. I couldn't possibly execute on all my startup ideas, which is why I'm sharing them here for free. If you pick one from the list, let me know. I'd love to watch them all come to fruition."
        pathname={location.pathname}
      />
      <Section>
        <SectionIntro style={{ marginTop: "100px" }}>
          <MainHeading>Startup Ideas</MainHeading>
          <Description>An idea's value comes from its execution. I couldn't possibly execute on all my startup ideas, which is why I'm sharing them here. I'd love to watch the good ones come to fruition.</Description>
        </SectionIntro>
      </Section>
      <ArticlesHero
        showAuthor={false}
        showHeading={false}
        authors={authors}
      />
      <Section narrow>
        <ArticlesList articles={ideas} tags={tags} />
        <IdeasPaginator show={pageContext.pageCount > 1}>
          <Paginator {...pageContext} />
        </IdeasPaginator>
        <BottomKernalAd>Looking for other ideas? Check out&nbsp;
          <Anchor
            target="_blank"
            rel="noopener nofollow"
            href="https://kern.al/register?invitecode=CAMERONNUCKOLS213168"
          >Kernal</Anchor>.
        </BottomKernalAd>
        {/* <IdeasGradient /> */}
      </Section>
    </Layout>
  );
}

export default IdeasPage;

const IdeasGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;

const Description = styled(Paragraph)`
  margin: 0px 0px 4vw 0px;
  font-size: 20px;

  ${mediaqueries.phablet`
    padding: 0px;
    margin-bottom: 50px;
  `};
`;

const BottomKernalAd = styled(Description)`
  margin-top: 10px;
`;

const SectionIntro = styled.div`
  margin-top: 100px;

  ${mediaqueries.phablet`
    margin-bottom: 100px;
  `};
`;

const MainHeading = styled(Headings.h1)`
  font-size: 34px;
  margin-bottom: 20px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Helvetica Neue, Helvetica, Ubuntu, Roboto, Noto, Segoe UI, Arial, sans-serif;

  ${mediaqueries.phablet`
    font-size: 30px;
  `};
`;

const IdeasPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && `margin-top: 95px;`}
`;
